





















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { BButton } from 'bootstrap-vue'
const QuantityField = () => import('@/components/QuantityField.vue')

@Component({
  components: { QuantityField, BButton }
})

export default class ProductCardMini extends Vue {
  /**
   * Тип мини карточки
   */
  @Prop({ default: 'basket', type: String })
  readonly type!:'basket'|string

  /**
   * Название продукта
   */
  @Prop({ default: 'Название', type: String })
  readonly title!:string

  /**
   * Вес (г)
   */
  @Prop({ default: 0, type: Number })
  readonly weight!:number

  /**
   * Цена без скидки
   */
  @Prop({ type: [Number] })
  readonly commonPrice!:number|null

  /**
   * Цена со скидкой
   */
  @Prop({ default: undefined, type: [Number] })
  readonly totalPrice!:number|undefined

  /**
   * url изображения
   */
  @Prop({ type: [String] })
  readonly imagery!:string|null

  /**
   * Скрыть кнопку добавления товара
   */
  @Prop({
    type: Boolean,
    default: false
  })
  readonly hideAddBtn!:boolean

  /**
   * Кол-во товара
   */
  @PropSync('quality', { type: [Number], default: null }) syncedQuality!: number|null

  /**
   * Вариант кнопки с ценой
   */
  @Prop({ type: [String] })
  readonly btnType?:'light'|'secondary'

  /**
   * Адрес страницы товара
   */
  @Prop({ type: [Object, String], default: '' })
  readonly productRoute!: Route | string | null

  /**
   * Скрыть кнопку добавления в корзину
   */
  @Prop({ type: Boolean, default: false })
  readonly hideAddToBasketBtn!: boolean

  async onClick(event: any) {
    if (!this.hideAddBtn) {
      this.$emit('click-btn', event)
    }
  }
}
